<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import XLSX from "xlsx";

import MapCategory from "@/components/MapCategoryGraph.vue";
import BarCategory from "@/components/BarsCategoryGraph.vue";
import LineCategory from "@/components/LineCategoryGraph.vue";
import LinesCategory from "@/components/LinesCategoryGraph.vue";
import PyramidCategory from "@/components/PyramidCategoryGraph.vue";
import StackedCategory from "@/components/StackedBarsCategoryGraph.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
import InlineSvg from "vue-inline-svg";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const current = ref(route.params.indice);
    const data = ref(null);
    const showTable = ref(false);
    const showCard = ref(false);
    const currentGraphId = ref(null);

    const currentGraph = computed(() => {
      if (!data.value) return null;
      return data.value.graphs.find((d) => d.id == currentGraphId.value);
    });

    const graphList = computed(() => {
      if (!data.value) return [];

      return data.value.graphs;
      // data.graphs[0].type
    });

    const category = computed(() =>
      store.getters.getCategory(route.params.category)
    );

    const indice = computed(() => {
      if (!category.value) return null;
      return category.value.indices.find((d) => d.slug == route.params.indice);
    });

    const csv = computed(() => {
      if (!data.value) return null;
      return store.getters.getCsvFromArray(data.value.data);
    });

    const getIndex = (id) => {
      axios.get(`${store.state.server}/end/colecciones/${id}`).then((d) => {
        data.value = d.data;

        if (d.data && d.data.graphs.length) {
          currentGraphId.value = d.data.graphs[0].id;
        }
      });
    };

    const getIndexBySlug = (e) => {
      let index = category.value.indices.find((d) => d.slug == e.target.value);
      getIndex(index.id);
      router.push({
        name: "Indice",
        params: { category: category.value.slug, indice: e.target.value },
      });
    };

    const generateExcel = (data) => {
      const header = Object.keys(data[0]);
      const rows = data.map((d) => {
        let res = [];
        for (let h of header) {
          res.push(d[h]);
        }

        return res;
      });
      const items = [header].concat(rows);
      const filename = "base.xlsx";
      const ws_name = "página 1";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(items);

      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);
    };

    if (indice.value) {
      getIndex(indice.value.id);
    } else {
      watch(indice, (indice) => {
        getIndex(indice.id);
      });
    }

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      // COMPUTED
      graphList,
      category,
      indice,
      current,
      data,
      csv,
      currentGraph,

      // FUNCTIONS
      generateExcel,
      getIndexBySlug,

      // REFS
      showTable,
      showCard,
      currentGraphId,
    };
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   */
  components: {
    MapCategory,
    PaginatedTable,
    InlineSvg,
    BarCategory,
    LineCategory,
    LinesCategory,
    PyramidCategory,
    StackedCategory,
  },
};
</script>
<template>
  <div class="about" v-if="indice">
    <!-- breadcrumb -->
    <div class="breadcrumb">
      <div class="container">
        <ul>
          <li><router-link :to="{ name: 'Home' }">Inicio</router-link></li>
          <li>
            <router-link :to="{ name: 'Categories' }">Estructura</router-link>
          </li>
          <li v-if="category">
            <router-link
              :to="{ name: 'Indices', params: { category: category.slug } }"
            >
              {{ category.name }}
            </router-link>
          </li>
          <li>{{ indice.title }}</li>
        </ul>
      </div>
    </div>
    <!-- indicador módulo/componente -->
    <div class="sg_cat_title">
      <div class="container">
        <div class="row">
          <div class="col-1">
            <div class="svg_icon">
              <inline-svg :src="require('../components/icons/vida-v2.svg')" />
            </div>
          </div>
          <div class="col-8">
            <p>{{ category.name }}</p>
          </div>
          <div class="col-sm-3 text-right">
            <router-link :to="{ name: 'Categories' }" class="sg_btn_cat">
              Más módulos o componentes [+]
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- categoría -->
    <div class="sg_cat_subtitle">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="mb-0">
              <strong>Categoría:</strong> {{ indice.categoria.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- indicador select -->
    <div class="sg_select_index">
      <div class="container">
        <div class="row">
          <div class="col-sm-10">
            <form class="mb-0">
              <div class="row">
                <div class="col-3">
                  <p>Selecciona indicador:</p>
                </div>
                <div class="col-9">
                  <p>
                    <select v-model="current" @change="getIndexBySlug">
                      <option
                        v-for="(index, i) of category.indices"
                        :key="`select-${i}`"
                        :value="index.slug"
                      >
                        {{ index.title }}
                      </option>
                    </select>
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div class="col-sm-2 text-right">
            <router-link
              :to="{ name: 'Indices', params: { category: category.slug } }"
              class="sg_btn_indicator"
              >Ver indicadores</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <h1 class="tite_indicator">{{ indice.title }}</h1>
            <div class="row mt-4">
              <div class="col-sm-12">
                <p>{{ indice.description }}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-10 offset-sm-2">
            <p v-if="graphList && graphList.length > 1">
              Seleccionar tipo de visualización:
              <select v-model="currentGraphId">
                <option
                  v-for="g of graphList"
                  :value="g.id"
                  :key="`list-graph-${g.id}`"
                  >{{ g.title }}</option
                >
              </select>
            </p>
            <map-category
              v-if="data && currentGraph && currentGraph.type == 1"
              :data="data.data"
              :config="currentGraph"
            />
            <stacked-category
              v-else-if="data && currentGraph && currentGraph.type == 2"
              :data="data.data"
              :config="currentGraph"
            />
            <bar-category
              v-else-if="data && currentGraph && currentGraph.type == 3"
              :data="data.data"
              :config="currentGraph"
            />
            <line-category
              v-else-if="data && currentGraph && currentGraph.type == 4"
              :data="data.data"
              :config="currentGraph"
            />
            <lines-category
              v-else-if="data && currentGraph && currentGraph.type == 5"
              :data="data.data"
              :config="currentGraph"
            />
            <pyramid-category
              v-else-if="data && currentGraph && currentGraph.type == 6"
              :data="data.data"
              :config="currentGraph"
            />
            <div class="mt-4"></div>
            <div class="gf_separator bottom"></div>
            <div v-if="data">
              <p class="text-right mb-0" v-if="data.source_url">
                Fuente:
                <strong
                  ><a :href="data.source_url" class="sg_t_link">{{
                    data.source
                  }}</a></strong
                >
              </p>
              <p class="text-right mb-0" v-else>
                Fuente: <strong>{{ data.source }}</strong>
              </p>
            </div>
            <div class="gf_separator bottom"></div>
            <div class="table">
              <p class="mb-0">
                <button
                  @click.prevent="showTable = !showTable"
                  class="btn_table mr-4"
                >
                  {{ showTable ? "Ocultar" : "Mostar" }} tabla
                  <b class="sg_i_table"></b>
                </button> 
                <button v-if="data && data.card"
                  @click.prevent="showCard = !showCard"
                  class="btn_table"
                >
                  {{ showCard ? "Ocultar" : "Mostar" }} ficha técnica
                  <b class="sg_i_table"></b>
                </button>
              </p>

              <h4 v-if="data && showTable" class="mt-3">Datos</h4>
              <paginated-table v-if="data && showTable" :rows="data.data" />

              <h4 v-if="data && data.card && showCard">Ficha técnica</h4>
              <paginated-table v-if="data && data.card && showCard" :rows="data.card" />
            </div>
            <div class="gf_separator bottom"></div>
            <p class="text-right mt-3" v-if="csv || data">
              Descargar datos:
              <a
                v-if="csv"
                :href="
                  `data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`
                "
                :download="`${indice.title}.csv`"
                class="btn_download"
                >csv</a
              >
              <a
                v-if="data"
                :href="
                  `data:text/json;charset=utf-8, ${JSON.stringify(data.data)}`
                "
                :download="`${indice.title}.json`"
                class="btn_download"
                >json</a
              >
              <button
                @click.prevent="generateExcel(data.data)"
                class="btn_download"
              >
                xlsx
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
