<script>
import { ref, computed } from "vue";

export default {
  props : ["rows"],
  setup(props){
    const page     = ref(1);
    const pageSize = 10;
    const pages    = computed( () => props.rows ? Math.ceil(props.rows.length / pageSize) : 1 );
    const nextPage = () => page.value = page.value < pages.value ? page.value+1: page.value;
    const prevPage = () => page.value = page.value > 1 ? page.value-1 : page.value;

    const labels = computed( () => {
      return Object.keys(props.rows[0]);
    });

    // const items = computed( () => {
    //   return props.rows //all.value ? (props.rows.length > maxItems ? props.rows.slice(0, maxItems) : props.rows) : props.rows.slice(0, minItems);
    // });

    const items = computed( () => {
      if(props.rows.length <= pageSize){
        return props.rows;
      }
      else{
        const realPage = page.value - 1;
        const step     = realPage * pageSize;
        const items    = props.rows.slice(step, step + pageSize);
        return items;
      }
    });

    return {
      labels,
      items,

      page,
      pages,
      nextPage,
      prevPage
    }
  }
}
</script>
<template>
  <div>
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th v-for="label of labels" :key="`lb-${label}`">{{label}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) of items" :key="`tr-${i}`">
          <td v-for="label of labels" :key="`lb-${label}`">{{item[label]}}</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination -->
    <div class="row mb-4">
      <div class="col-sm-12">
        <div class="row">
          <div class="col">
            <button  v-if="page > 1" @click.prevent="prevPage" class="sg_netprev">&lt;anterior</button>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-6 pr-1">
                <p><input class="ser_pag_input" v-model="page" /> </p>
              </div>
              <div class="col-6 pl-0 sg_pag">
                <p>/ {{pages}}</p>
              </div>
            </div>
          </div>
        <div class="col text-right"  v-if="page < pages">
          <button v-if="page < pages " @click.prevent="nextPage" class="sg_netprev" >siguiente&gt;</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>