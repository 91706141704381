<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref, computed, onMounted, watch} from "vue";
import {useStore} from "vuex";
import {format} from "d3-format"
// import {select} from "d3-selection";

import { scaleBand , scaleLinear} from "d3-scale";
// import {/*axisBottom,*/ axisLeft} from "d3-axis";
import {stack} from "d3-shape";

import FileSaver from "file-saver";

import Canvg, {
    presets
} from 'canvg';

import chroma from "chroma-js";

import CategoryList from '@/components/CategoryListUI.vue';
import Tooltip from "@/components/TooltipUI.vue";

import {stackedBarsCategory} from "@/graphsConfig.js";

import getFilters from "@/composables/filters.js";
import getTooltip from "@/composables/tooltip.js";
// import getData from "@/composables/data.js";

/**
 * CONSTANTS
 * --------------------------------------------------------------------------------
 */
const {
  margin, 
  height,
  xLabel, 
  yLabel,
  cLabel,
  barWidth : bw,
  minWidth,
  colors : colorList,
  ticks
} = stackedBarsCategory;

const f = format(",")
/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ["data", "config"],
  setup(props){
    const store = useStore();
    const containter = ref(null);
    /**
     * FILTERS
     * 
     */
    const {filters} = getFilters(props);
    const values    = ref( filters.value.map( d => d.items[0]) );
    // filters update function
    const updateValues = (e, i) => {
      values.value[i] = e.target.value;
      updateScales();
    }

    const updateScales = () => {
      // xAxis.value = axisBottom(xScale.value).tickSizeOuter(0);
      // yAxis.value = axisLeft(yScale.value).ticks(null, "s");
      // gx.value    = select(svg.value).append("g").attr("transform", `translate(0, ${height - margin.bottom})`);
      // gy.value    = select(svg.value).append("g").attr("transform", `translate(${margin.left}, 0)`);
      
      // gx.value.call(xAxis.value);
      // gy.value.call(yAxis.value)
    }

    /**
     * TOOLTIP
     * 
     */
    const {onHover, onMove, showTooltip, clientX, clientY, tooltipItem} = getTooltip();

    /**
     * THE DATA HANDLING
     * 
     */
    const config = computed( () => props.config);
    // the axis definition
    const xColumn = computed( () => props.config.fields.find(d => d.label == xLabel).column );
    const yColumn = computed( () => props.config.fields.find(d => d.label == yLabel).column );
    const cColumn = computed( () => props.config.fields.find(d => d.label == cLabel).column );

    const colors = computed( () => {
      let categories = [...new Set(mappedData.value.map(d => d.stack))];
      if(categories.length <= colorList.length){
        return colorList;
      }
      else{
        return chroma.scale(colorList).mode('lch').colors(categories.length);
      }
    });
    
    // base data mapping
    // const {stackedMappedData: mappedData} = getData(props, filters, null, xColumn, yColumn, values, cColumn)
    const mappedData = computed ( () => {

      let filters = props.config.filters;
      let data = props.data.map( d => {
        let item = {};
        item.id = new Date().valueOf();
        item.category = d[xColumn.value];
        item.value    = d[yColumn.value];
        item.stack    = d[cColumn.value];
        for(let f of filters){
          item[f] = d[f];
        }
        item.data = d;
        return item;
      });
      return data;
    });

    // filter base data
    // const {currentData} = getData(props, filters, mappedData, xColumn, yColumn, values)
    const currentData = computed( () => {
      return mappedData.value.filter(d => {
        let check = [];
        for(let i = 0; i< filters.value.length; i++){
          check.push(d[filters.value[i].name] == values.value[i])
        }
        return check.filter(d => d).length == filters.value.length;
      })
    })

    // the stack unique values
    const categories = computed( () => [...new Set(props.data.map( d => d[cColumn.value]))].sort() );
    
    // the x columns unique values
    const xitems  =  computed( () => {
      return [...new Set(currentData.value.map( d => d.category) )]
    });

    const barWidth = computed( () => {
      const items = xitems.value.length;
      const containerWidth =  containter.value ? containter.value.offsetWidth : 0;
      const minW           = containerWidth > minWidth ? containerWidth : minWidth;
      if(!items) return bw;
      
      // const minWidth = 400;
      const total = items * bw;
      return total > minW ? bw : minW/items;
    });

    // the objects for the d3 stack graph
    const graphItems = computed( () => {
      let mapped;
      
      mapped = xitems.value.map( category => {
        let item = {category }
        for(let el of categories.value){
          let i = currentData.value.filter(d => d.data[cColumn.value] == el && d.data[xColumn.value] == category);
          item[el] = i.length ? i[0].value : 0;
        }
        return item;
      });

      return mapped;
    });

    // the d3 stack graph
    const st = computed( () => {
      return stack().keys( categories.value )
    });

    const series = computed( () => {
      return st.value(graphItems.value)
    });

    /**
     * THE SCALES
     * 
     */
    const xScale = computed( () => {
      return scaleBand()
        .domain( xitems.value )
        .range([margin.left, width.value - margin.right])
        .padding(.1)
    });

    const yScale = computed( () => {
      return scaleLinear()
        .domain([0, Math.max(... series.value[categories.value.length-1].map( d => d[1]))])
        .rangeRound([height - margin.bottom, margin.top])
      // if(yColumn.value){
      //   // let values = currentData.value.map( d => +d.value);
      //   return scaleLinear()
      //     // .domain([ Math.min(... values),Math.max(... values) ])
      //     .domain([0, Math.max(... series.value[categories.value.length-1].map( d => d[1]))])
      //     .rangeRound([height - margin.bottom, margin.top])
      // }
      // else{
      //   return scaleLinear()
      //   .domain([0, Math.max(... series.value[categories.value.length-1].map( d => d[1]))])
      //   .rangeRound([height - margin.bottom, margin.top])
      // }
    });


    /**
     * THE RENDER ITEMS
     * 
     */
    const width = computed( () => {
      return margin.left + margin.right + (barWidth.value * xitems.value.length)
    });

    // const xAxis = ref(null);
    // const yAxis = ref(null);
    // const gx    = ref(null);
    // const gy    = ref(null);

    onMounted( () => {
      // xAxis.value = axisBottom(xScale.value).tickSizeOuter(0);
      // yAxis.value = axisLeft(yScale.value).ticks(null, "s");
      // gx.value    = select(svg.value).append("g").attr("transform", `translate(0, ${height - margin.bottom})`);
      // gy.value    = select(svg.value).append("g").attr("transform", `translate(${margin.left}, 0)`);
      
      // gx.value.call(xAxis.value);
      // gy.value.call(yAxis.value)
    });

    watch(config, /*config*/() => {
      values.value = filters.value.map( d => d.items[0]);
      updateScales();
    })

    const svg    = ref(null);

    const saveImage = async () => {
      const canvas = new OffscreenCanvas(width.value, height);
      const ctx    = canvas.getContext('2d');
      const v      = await Canvg.fromString(ctx, svg.value.outerHTML, presets.offscreen());

      await v.render();

      const blob = await canvas.convertToBlob();
      const pngUrl = URL.createObjectURL(blob);

      FileSaver.saveAs(pngUrl, "image.png")
    }

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      showTooltip,
      clientX,
      clientY,
      tooltipItem,

      svg,
      containter,
      margin,
      height,
      width,
      barWidth,
      xScale,
      yScale,
      filters,
      categories,
      values,
      currentData,
      //test
      graphItems,
      mappedData,
      yColumn,
      f,
      ticks,

      updateValues,
      onHover,
      onMove,
      saveImage,
      // xAxis

      xitems,
      series,
      colors,// : colorList
      short : store.getters.translateLabel
    }

  },
  components : {
    Tooltip,
    CategoryList
  }
}
</script>

<template>
  <div class="sg_viz">
    <div class="row mt-4">
      <div class="col-12">
        <!-- the filters -->
      <div v-if="filters.length">
        <ul class="row sg_filters">
          <li v-for="(filter, i) of filters" :key="`filter-${i}`" class="col">
            {{filter.name}}
            <select @change="e => updateValues(e, i)">
              <option v-for="(opt,j) of filter.items" :key="`fil-${opt}-${i}-${j}`">
                {{opt}}
              </option>
            </select>
          </li>
        </ul>
      </div>
      
      <!-- category list -->
      <div class=" mt-3">
        <category-list :categories="categories" :colors="colors" :current="currentData" />
      </div> 
      
      </div>
      <div class="col-12">
        <div class="sg_dataviz" ref="containter">

    <svg ref="svg" xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width="100%" height="100%" :viewBox="`0 0 ${width} ${height}`" stroke-linecap="round" stroke-linejoin="round" class="sg_svg">
      <g v-for="(row,i) of series" :key="`serie-${i}`"> 
        <rect v-for="(r, j) of row" 
        :x="xScale(xitems[j])"
        :y="yScale(r[1])"
        :height="yScale(r[0]) - yScale(r[1])"
        :width="xScale.bandwidth()"
        :fill="colors[i]"
        @mouseover="e => onHover(e, r)" 
        @mousemove="onMove" 
        @mouseout="showTooltip = false"
        :key="`serie-${i}-${j}`" />
      </g>
      <!-- xScaleAxis -->
      <g :transform="`translate(0, ${height - margin.bottom})`">
        <!-- ticks -->
        <g v-for="(tick, i) of xScale.domain()" :transform="`translate(${xScale(tick) + xScale.bandwidth()/2}, 0)`" :key="`x-tick-${i}`">
          <line x1="0" y1="0" x2="0" :y2="3" stroke="black" />
          <text x="0" y="5" text-anchor="middle" alignment-baseline="hanging" :font-size="ticks.fontSize">{{short(tick)}}</text>
        </g>
        
        <!-- Axis -->
        <line
          :x1="margin.left"
          y1="0"
          :x2="width - margin.right"
          y2="0"
          stroke="black" />
      </g>



      <!-- yScaleAxis -->
      <g :transform="`translate(${margin.left},0)`">
        <!-- ticks -->
        <g v-for="(tick, i) of yScale.ticks()" :transform="`translate(0, ${yScale(tick)})`" :key="`x-tick-${i}`">
          <line :x1="-6" 
                y1="0" 
                :x2="0" 
                y2="0" 
                stroke="black"
                :fill-opacity="ticks.opacity" />
          <text y="0" x="-9" text-anchor="end" alignment-baseline="middle" :font-size="ticks.fontSize">{{f(tick)}}</text>
        </g>

        <!-- Axis -->
        <line
          :x1="0"
          :y1="margin.top"
          :x2="0"
          :y2="height - margin.bottom"
          stroke="black" />
      </g>
    </svg>
    </div>
      </div>


       
    </div>
        
    <p class="mt-3"><button @click.prevent="saveImage" class="btn_image">Guardar imagen <b class="sg_i_image"></b></button></p>
    <tooltip  :clientX="clientX" :clientY="clientY" :show="showTooltip" :labels="config.labels" :item="tooltipItem" />
  </div>
</template>